/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BasisSchema } from './BasisSchema';
import type { BERetrieveSchema } from './BERetrieveSchema';
import type { DeliveryDateSource } from './DeliveryDateSource';
import type { schemas__client__BEOptions } from './schemas__client__BEOptions';
import type { schemas__client__BusinessEntityIntegrationType } from './schemas__client__BusinessEntityIntegrationType';
import type { schemas__client__LimitsUploadType } from './schemas__client__LimitsUploadType';
import type { schemas__client__PFMRetrieveSchema } from './schemas__client__PFMRetrieveSchema';
import type { schemas__client__RequisitesRetrieveSchema } from './schemas__client__RequisitesRetrieveSchema';
export type BERetrieveWithOptionsSchema = {
    clientId: number;
    name: string;
    legalEntity?: string;
    extCode?: string;
    okpo: string;
    autoSolverAddRelatedModels?: boolean;
    autoErpOrderCreate?: boolean;
    autoAcceptErpSupplierOrder?: BERetrieveWithOptionsSchema.auto_accept_erp_supplier_order;
    addRelatedModels?: boolean;
    erpLimitCheck?: boolean;
    autoRepeatErpSolver?: boolean;
    autoRepeatErpSolverTimeH?: number;
    workPhone?: string;
    legalAddress?: string;
    actualAddress?: string;
    ogrn?: string;
    website?: string;
    mpLimitsCheck?: boolean;
    ociAutoAddRelatedModels?: boolean;
    ociAddRelatedModels?: boolean;
    mpOrderMaxCost?: number;
    mpOrderMinCost?: number;
    erpOrderMaxCost?: number;
    erpOrderMinCost?: number;
    canSplit?: boolean;
    canPartialCover?: boolean;
    quantOverflow?: boolean;
    fixedLimit?: boolean;
    integrationType?: schemas__client__BusinessEntityIntegrationType;
    limitsUploadType: schemas__client__LimitsUploadType;
    deliveryOrganizationDays?: number;
    deliveryDateSource?: DeliveryDateSource;
    orderApproveDays?: number;
    maxErpSuborderDeliveryPercent?: number;
    ociUseAllowedCategory?: boolean;
    hasMtrCode?: boolean;
    isPackingListRequired?: boolean;
    allowSupplierCargoSpace?: boolean;
    allowExceedingPlanDate?: boolean;
    bases?: Array<BasisSchema>;
    options?: schemas__client__BEOptions;
    id: number;
    parentId?: number;
    businessEntityType: string;
    requisites?: schemas__client__RequisitesRetrieveSchema;
    pfm?: schemas__client__PFMRetrieveSchema;
    isDeleted: boolean;
    isDeliveryInboundRequired: boolean;
    inn: string;
    kpp: string;
    paymentAccount: string;
    rcbic: string;
    correspondentAccount: string;
    bankBranch?: string;
    bankInn: string;
    bankKpp: string;
    mainDivision?: BERetrieveSchema;
};
export namespace BERetrieveWithOptionsSchema {
    export enum auto_accept_erp_supplier_order {
        TRUE = 'true',
        FALSE = 'false',
        CUSTOM = 'custom',
    }
}

