/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type ThinStringInputAttribute = {
    group: string;
    displayName?: string;
    caption?: string;
    mandatory: boolean;
    important: boolean;
    purpose?: Array<string>;
    sequence: number;
    similarityWeight?: number;
    name: string;
    valueOptions?: Array<string>;
    multiValue?: boolean;
    dataType: ThinStringInputAttribute.data_type;
};
export namespace ThinStringInputAttribute {
    export enum data_type {
        STRING = 'STRING',
    }
}

